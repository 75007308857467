import { message } from 'antd';
import axios from 'axios';
import { Mapping, ProjectModel } from 'digicust_types';
import { GENERIC_URL } from './constants';

export class ProjectsRepository {
  public static newProject = async (
    customerId: string,
    projectName: string,
  ): Promise<any> => {
    try {
      const response = await axios.post(
        `${GENERIC_URL}/api/customers/${customerId}/projects`,
        { name: projectName },
      );
      return response.data.projectId || response.data.id;
    } catch (error) {
      console.log(error);
      message.error('Something went wrong.');
      return null;
    }
  };
  public static getList = async (
    customerId?: string,
  ): Promise<ProjectModel[]> => {
    try {
      const response = await axios.get(
        `${GENERIC_URL}/api/customers/${customerId}/projects`,
      );
      return Array.isArray(response.data) ? response.data : [];
    } catch (error) {
      console.log(error);
      message.error('Something went wrong.');
      return [];
    }
  };

  public static get = async (
    customerId?: string,
    projectId?: string,
  ): Promise<ProjectModel> => {
    const response = await axios.get(
      `${GENERIC_URL}/api/${customerId}/${projectId}/project-details`,
    );
    return response.data;
  };

  public static save = async (
    customerId?: string,
    projectId?: string,
    projectData?: ProjectModel,
  ): Promise<any> => {
    try {
      const response = await axios.patch(
        `${GENERIC_URL}/api/${customerId}/${projectId}/project-details`,
        projectData,
      );
      return response.data;
    } catch (error) {
      console.log(error);
      message.error('Something went wrong.');
      return null;
    }
  };

  public static getSavedMappings = async (
    customerId?: string,
    projectId?: string,
  ): Promise<Mapping[]> => {
    const response = await axios.get(
      `${GENERIC_URL}/api/${customerId}/${projectId}/mappings`,
    );

    return response.data;
  };

  public static saveUpdateMapping = async (
    customerId?: string,
    projectId?: string,
    mapping?: Mapping,
  ): Promise<Mapping[]> => {
    const response = await axios.post(
      `${GENERIC_URL}/api/${customerId}/${projectId}/mappings`,
      mapping,
    );

    return response.data;
  };

  public static deleteMapping = async (
    customerId?: string,
    projectId?: string,
    mappingId?: string,
  ): Promise<Mapping[]> => {
    const response = await axios.delete(
      `${GENERIC_URL}/api/${customerId}/${projectId}/mappings/${mappingId}`,
    );

    return response.data;
  };

  public static mapUsingAi = async (
    customerId?: string,
    projectId?: string,
    paths?: { path: string; value: string }[],
  ): Promise<Mapping[]> => {
    const response = await axios.post(
      `${GENERIC_URL}/api/${customerId}/${projectId}/mappings/gpt`,
      paths,
    );

    return response.data;
  };
}

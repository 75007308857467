import { useAccount, useMsal } from '@azure/msal-react';
import { message } from 'antd';
import { AxiosError } from 'axios';
import { MembershipUser, ProjectModel } from 'digicust_types';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import DCSpinner from '../common/FullPageLoader';
import { PermissionsRepository } from '../utils/repositories/permissions.repository';
import { ProjectsRepository } from '../utils/repositories/projects.repository';

type AppData = {
  customerId: string;
  projectId: string;
  isAdmin: boolean;
  user?: MembershipUser;
  projectDetails?: ProjectModel;
  loadProject: () => Promise<void>;
};

const AppDataContext = React.createContext<AppData>({} as AppData);

export function useAppDataContext() {
  return useContext(AppDataContext);
}

export default function AppDataProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const { customerId, projectId } = useParams();

  const { t } = useTranslation();

  const [user, setUser] = useState<any>();
  const { accounts } = useMsal();
  const account = useAccount(accounts[0]);
  const navigate = useNavigate();

  const [projectDetails, setProjectDetails] = useState<ProjectModel>();

  const loadProject = async (showWarning?: boolean) => {
    if (!customerId || !projectId) return;

    try {
      const project = await ProjectsRepository.get(customerId, projectId);

      if (showWarning && project?.taricClassifications) {
        const percent =
          (project?.taricClassifications?.total /
            project?.taricClassifications?.limit) *
          100;

        if (percent > 90)
          message.warning(`${percent}% ${t('classification limit utilized.')}`);
      }
      setProjectDetails(project);
    } catch (error: unknown) {
      if ((error as AxiosError).status === 401) {
        console.log('UnAuthorized - ', error);
        navigate('/error');
      }
    }
  };

  const loadUserDetails = async () => {
    if (!customerId || !projectId || !account?.localAccountId) return;

    const user = await PermissionsRepository.getUserDetails(
      customerId,
      projectId,
      account?.localAccountId,
    );

    setUser(user);
  };

  useEffect(() => {
    loadProject(true);
    loadUserDetails();
  }, [customerId, projectId, account?.localAccountId]);

  const context: AppData = useMemo(() => {
    const data: AppData = {
      customerId: customerId!,
      projectId: projectId!,
      isAdmin: user?.role === 'admin',
      user,
      projectDetails,
      loadProject,
    };
    return data;
  }, [customerId, projectId, user, projectDetails]);

  if (!projectDetails) {
    return <DCSpinner fullPage />;
  }

  return (
    <AppDataContext.Provider value={context}>
      {children}
    </AppDataContext.Provider>
  );
}
